<template>
  <div class="d-none">
    <b-modal
      v-model="showModal"
      ref="confirmCancelModal"
      centered
      hide-header
      hide-footer
    >
      <div class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="hide">
          ×
        </button>
      </div>
      <h4 class="text-center">ลายเซ็น</h4>
      <div class="px-md-5 py-3">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="radio-group-signatures"
            v-model="form.signatureId"
            :options="signatureLsit"
            :aria-describedby="ariaDescribedby"
            name="radio-signatures"
            value-field="id"
            text-field="name"
          ></b-form-radio-group>
        </b-form-group>
        <div class="text-danger f-14" v-if="$v.form.signatureId.$error">
          *กรุณาเลือกลายเซ็น
        </div>
      </div>
      <div class="form-inline justify-content-center mt-2">
        <b-button class="mr-2 btn-modal" variant="cancel" @click="hide">ยกเลิก</b-button>
        <b-button class="ml-2 btn-modal" variant="main" @click="confirm"
          >ยืนยัน</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  props: {
    orderId: {
      required: true,
      type: [String, Number],
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        signatureId: 0,
      },
      signatureLsit: [],
    };
  },
  validations() {
    return {
      form: {
        signatureId: {
          required,
        },
      },
    };
  },
  methods: {
    show() {
      this.getOrderExportSignature();
      this.$v.form.$reset();
      this.form = {
        signatureId: 0,
      };
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;

      this.$emit("confirm", this.form);
      this.hide();
    },
    getOrderExportSignature: async function () {
      let res = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/Transaction/ExportOrderDetail/OrderExportSignature`,
        null,
        this.$headers,
        null
      );
      if (res.result == 1) {
        this.signatureLsit = res.detail;
      }
    },
  },
};
</script>

<style scoped>
.modal-header {
  border-bottom: 0 !important;
  margin: 0px;
  padding: 0px;
  padding-bottom: 0.25rem;
}
.text-msg {
  font-size: 2rem;
  color: #4a4a4a;
}
.text-text {
  color: #4a4a4a;
  margin-bottom: 0;
  font-size: 1.2rem;
}
.text-sub {
  color: #afce38;
  font-size: 1.25rem;
}
.modal-body {
  margin: auto;
  text-align: center;
  padding: 0;
}
.btn-modal {
  width: 150px;
  font-weight: bold;
}
</style>
